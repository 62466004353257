import { Main } from "components"
import React from "react"
import ScheduleCallPage from "components/pages/schedule-call"

const ScheduleCall = () => {
	return (
		<Main>
			<ScheduleCallPage />
		</Main>
	)
}

export default ScheduleCall
