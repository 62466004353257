import React from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import { InlineWidget } from "react-calendly"
import tick_icon from "assets/icons/tick.svg"
import * as styles from "./styles.module.scss"

const ScheduleCallPage = () => {
	return (
		<Container className=" ">
			<Row className="align-items-center">
				<Col md={2} className={styles.leftCard}>
					<div className="text-center">
						<Image src={tick_icon} fluid />
						<h6 className="mt-4 fw-bold">Thank you for the interest</h6>
						<p>We will review your information and will get back to you</p>
					</div>
				</Col>
				<Col md={10} className={styles.rightCard}>
					<InlineWidget url="https://calendly.com/shahzeb-khalid/30min" />
				</Col>
			</Row>
		</Container>
	)
}

export default ScheduleCallPage
